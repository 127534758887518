/**
 * This function will be triggered on the client to initiate the comments module. It requires a number of
 * exposed properties on the window.YAHOO and window.rapidPageConfig objects.
 * @return {Void} Void
 */
(function(window) {
    const { CAAS_COMMENTS, rapidInstance, rapidPageConfig, YAHOO } = window;
    // YAHOO.context is exposed via the kaizen-context component
    const { device = 'desktop', lang, meta = {}, region, site } = YAHOO.context;

    const { commentsAllowed, messageId, replyId, title, url, uuid } = meta;

    if (!YAHOO || !YAHOO.CanvassComments || !commentsAllowed) {
        console.error(
            '[comments] Missing required window.YAHOO or winodw.YAHOO.CanvassComments objects or comments not allowed on this page'
        );
        return null;
    }

    // rapidPageConfig and SPACEID is exposed via the kaizen-rapid component
    const { keys: rapidConfigKeys = {} } = rapidPageConfig;
    rapidConfigKeys.p_cpos = '';
    rapidConfigKeys.pct = 'comments';

    const spaceId = YAHOO.i13n && YAHOO.i13n.SPACEID;

    try {
        CAAS_COMMENTS && CAAS_COMMENTS.destroy();
        let bcmt = 0;
        if (document && document.location.search.indexOf('bcmt') >= 1) {
            bcmt = 1;
        }

        const commentConfig: any = {
            apptype: 'default',
            context: uuid || '',
            contextDisplayText: title.replace(/'/g, '%27') || '',
            contextUrl: url || '',
            device,
            expanded: bcmt,
            namespace: 'yahoo_content',
            spaceid: spaceId,
            lang,
            region,
            site,
            theme: 'redesign',
            themeContainerClasses: 'Ff(YahooSans)',
            trafficSource: device === 'smartphone' && 'article_20',
            rapid: rapidInstance,
            rapidKeys: rapidConfigKeys,
            useRelativeHostName: 1
        };

        if (messageId) {
            commentConfig.messageId = messageId;
            if (replyId) {
                commentConfig.replyId = replyId;
            }
        }

        window.CAAS_COMMENTS = new YAHOO.CanvassComments(
            'community-bar',
            commentConfig
        );
        const caasComment = document.getElementsByClassName('caas-comment')[0];
        caasComment &&
            caasComment.addEventListener('click', _commentClickHandler);
    } catch (e) {
        // do nothing
    }

    /**
     * This is a handler method that listens to comment click event
     * @param {Object} e event received
     */
    function _commentClickHandler(e) {
        e.preventDefault();
        e.stopPropagation();

        const caasCommentIframe = document.getElementById(
            'community-bar-iframe'
        );
        const SCROLL_DURATION = 1000;
        const SCROLL_MARGIN_TOP = -150;
        const easeInCubic = function(t) {
            return t * t * t;
        };

        // util method to scroll to comments
        const scrollToComments = (
            startTime,
            currentTime,
            duration,
            targetElemTop,
            startScrollOffset
        ) => {
            const runtime = currentTime - startTime;
            let progress = runtime / duration;
            progress = Math.min(progress, 1);
            const ease = easeInCubic(progress);
            window.scroll(0, startScrollOffset + targetElemTop * ease);
            if (runtime < duration) {
                requestAnimationFrame(timestamp => {
                    const currentTime = timestamp || new Date().getTime();
                    scrollToComments(
                        startTime,
                        currentTime,
                        duration,
                        targetElemTop,
                        startScrollOffset
                    );
                });
            }
        };

        // fires a click beacon
        if (rapidInstance) {
            rapidInstance.beaconClick(
                'cmmts',
                'Reaction',
                0,
                {
                    g: uuid,
                    elm: 'cmmt_open',
                    itc: 0
                },
                null
            );
        }

        const contentWindow =
            // @ts-ignore
            caasCommentIframe && caasCommentIframe.contentWindow;
        // on clicking the comments button on desktop scroll down to the comments section and expand comments
        if (contentWindow) {
            // fire 'canvass:expand' event to expand the comments canvass
            contentWindow.postMessage('canvass:expand', '*');

            // this animation is not needed for smartphone
            if (device !== 'smartphone') {
                requestAnimationFrame(timestamp => {
                    const stamp = timestamp || new Date().getTime();
                    const duration = SCROLL_DURATION;
                    const start = stamp;
                    const startScrollOffset = window.pageYOffset;
                    const caasCommentIframeTop =
                        caasCommentIframe.getBoundingClientRect().top +
                        SCROLL_MARGIN_TOP;
                    scrollToComments(
                        start,
                        stamp,
                        duration,
                        caasCommentIframeTop,
                        startScrollOffset
                    );
                });
            }
        }
    }
})(window);
